import type { ExtendedVerticalType, VerticalType } from 'models/product';

import {
  ENGLISH_LOCALE_IDENTIFIER,
  GERMAN_LOCALE_IDENTIFIER,
  Locale,
  LOCALES,
} from './i18n';
import { BUSINESS_SUBDOMAIN, DEFAULT_SUBDOMAIN, Subdomain } from './subdomains';

interface Link {
  path: string;
  supportedLocales: Locale[];
  subdomains: Subdomain[];
}

// for policy link section
export const productLinks: Record<VerticalType | ExtendedVerticalType, string> =
  {
    PUBLIC_HEALTH: '/health-insurance/public',
    EXPAT_HEALTH: '/health-insurance/expat',
    PRIVATE_HEALTH: '/health-insurance/private',
    DENTAL: '/dental-insurance',
    LIABILITY: '/personal-liability-insurance',
    HOUSEHOLD: '/household-insurance',
    LEGAL: '/legal-insurance',
    LIFE: '/life-insurance',
    BIKE: '/bike-insurance',
    DISABILITY: '/disability-insurance',
    TRAVEL: '/travel-insurance',
    DOG_LIABILITY: '/dog-liability-insurance',
    COMPANY_HEALTH: '/company-health-insurance',
    COMPANY_PENSION: '/company-pension',
    COMPANY_TRAVEL: '/company-travel-insurance',
    COMPANY_DISABILITY: '/disability-insurance',
    COMPANY_LIFE: '/company-life-insurance',
    PET_HEALTH: '/pet-health-insurance',
    PENSION: '/pension-insurance',
  };

type NonProduct =
  | 'CAREER'
  | 'ABOUT_US'
  | 'BECOME_A_PARTNER'
  | 'SUPPORT'
  | 'FEATHER_IN_DEPTH'
  | 'PRESS';

// config for which locale to show/hide in nav, footer, sitemap
export const nonProductLinks: Record<NonProduct, Link> = {
  CAREER: {
    path: '/careers',
    supportedLocales: [ENGLISH_LOCALE_IDENTIFIER],
    subdomains: [DEFAULT_SUBDOMAIN],
  },
  ABOUT_US: {
    path: '/about-us',
    supportedLocales: LOCALES,
    subdomains: [DEFAULT_SUBDOMAIN, BUSINESS_SUBDOMAIN],
  },
  SUPPORT: {
    path: '/support',
    supportedLocales: LOCALES,
    subdomains: [DEFAULT_SUBDOMAIN],
  },
  FEATHER_IN_DEPTH: {
    path: '/feather-in-depth',
    supportedLocales: [ENGLISH_LOCALE_IDENTIFIER],
    subdomains: [DEFAULT_SUBDOMAIN],
  },
  PRESS: {
    path: '/press',
    supportedLocales: [ENGLISH_LOCALE_IDENTIFIER],
    subdomains: [DEFAULT_SUBDOMAIN],
  },
  BECOME_A_PARTNER: {
    path: '/become-a-partner',
    supportedLocales: [
      GERMAN_LOCALE_IDENTIFIER,
      ENGLISH_LOCALE_IDENTIFIER,
      'en-es',
    ],
    subdomains: [DEFAULT_SUBDOMAIN],
  },
};
