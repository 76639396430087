import '@popsure/dirty-swan/dist/index.css';
import 'css/app.scss';
import 'css/index.scss';
import 'css/animation.scss';
import '@getpopsure/cookie-consent/dist/style.css';

import { CookieBanner } from '@getpopsure/cookie-consent';
import { setCookieLocale, setCookieRegion } from '@getpopsure/i18n-react';
import { socialMedia, website } from '@getpopsure/private-constants';
import {
  initialize as initializeUtmTracker,
  populateTrackingObjectFromUrl,
} from '@getpopsure/tracker';
import { GoogleTagManager } from '@next/third-parties/google';
import Footer from 'components/footer';
import { setupIntercomConfiguration } from 'components/IntercomLoaderWrapper/utils/setupIntercomConfiguration';
import NavigationBar from 'components/navigationBar';
import { FEATHER_DOMAIN } from 'constants/cookies';
import { ENGLISH_LOCALE_IDENTIFIER } from 'constants/i18n';
import { BUSINESS_SUBDOMAIN } from 'constants/subdomains';
import { getFeatureFlags } from 'endpoint';
import { useClient } from 'hooks/useClient';
import { useNavOffset } from 'hooks/useNavOffset';
import { AppContext, AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { appWithTranslation } from 'next-i18next';
import nextI18NextConfig from 'next-i18next.config.js';
import { DefaultSeo, OrganizationJsonLd } from 'next-seo';
import React, { useEffect, useState } from 'react';
import { LiveChatLoaderProvider } from 'react-live-chat-loader';
import { handleFinanceAdsCookie } from 'services/financeAds';
import { useTracker } from 'services/tracker';
import { fetchMany, fetchOne } from 'strapi/utils/api';
import { canonicalUrlForRouter } from 'util/canonicalUrlForRoute';
import { isDevelopment, isProduction } from 'util/env';
import { getLanguageFromLocaleIdentifier } from 'util/getCountryFromLocaleIdentifier';
import {
  getFooterFromStrapiNavigationData,
  getMenuFromStrapiNavigationData,
} from 'util/getMenuFromCmsNavigationData';
import {
  getLocaleCookieFromLocaleIdentifier,
  getRegionCookieFromLocaleIdentifier,
} from 'util/getRedirectPathFromLocaleCookie';
import { validateLocale } from 'util/i18nLocaleFormatter';
import { validateSubdomain } from 'util/validateSubdomain';

import { setTrackersAfterConsent } from '../services/tracker/setTrackersAfterConsent';

declare const AOS: {
  init: () => void;
  refresh: () => void;
  refreshHard: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type CustomAppProps<P = any> = {
  pageProps: P;
  mainNav: NavigationProductCategory[];
  footer: FooterProductsMenu;
  isHiring: boolean;
  isBusinessLanguageSwitcherActive?: boolean;
} & Omit<AppProps<P>, 'pageProps'>;

/**
 * Initialize the cookie tracker
 * to allow debugging of utm params on dev environment
 */
const cookieTrackerOptions = isDevelopment
  ? { cookieAttributes: { expires: 30, domain: 'localhost' } }
  : {};

initializeUtmTracker(cookieTrackerOptions);

const MyApp = ({
  Component,
  pageProps,
  mainNav,
  footer,
  isHiring,
  isBusinessLanguageSwitcherActive,
}: CustomAppProps) => {
  const router = useRouter();
  const hasMounted = useClient();
  const { navOffset, allRefs } = useNavOffset();

  const [scrolled, setScrolled] = useState(false);
  const [subdomainSegment, setSubdomainSegment] = useState<
    string | string[] | undefined
  >(router.query.subdomain);

  const language = getLanguageFromLocaleIdentifier(router.locale);
  const locale = validateLocale(router.locale);
  const subdomain = validateSubdomain(subdomainSegment);
  const canonicalUrl = canonicalUrlForRouter(router, subdomain);
  const shouldShowLanguageSwitcher = !(
    subdomain === BUSINESS_SUBDOMAIN && !isBusinessLanguageSwitcherActive
  );

  setupIntercomConfiguration();
  useTracker();

  useEffect(() => {
    /**
     * Get the dynamic segment [subdomain] of rewritten internal URL
     * (See middleware.page.ts for where this is set)
     * before component finishes mounting.
     * This condition is needed because after component has mounted,
     * Next router treats first segment of [...uid] as [subdomain].
     * This leads to an unexpected value of router.query.subdomain on a page of valid subdomain.
     * e.g. {subdomain: 'health-insurance'} instead of {subdomain: 'default'}
     * for '/health-insurance/public' rewritten as '/default/health-insurance/public'
     */
    if (!hasMounted) {
      setSubdomainSegment(router.query.subdomain);
    }
  }, [hasMounted, router.query.subdomain]);

  useEffect(() => {
    const setLocaleCookie = () => {
      const currentLocale = router.locale || 'en-de';

      setCookieLocale(
        getLocaleCookieFromLocaleIdentifier(currentLocale),
        FEATHER_DOMAIN
      );

      // set the region cookie.
      // for now this is hardcoded for testing purposes.
      // we will move the logic to the appropriate library and import that functionality.
      const region = getRegionCookieFromLocaleIdentifier(currentLocale);
      setCookieRegion(region, FEATHER_DOMAIN);
    };

    setLocaleCookie();
  }, [router.locale]);

  useEffect(() => {
    if (hasMounted) {
      handleFinanceAdsCookie();
      populateTrackingObjectFromUrl();
    }
  }, [hasMounted]);

  useEffect(() => {
    // TODO: rewrite this function as router.events is not longer supported in Next 13
    const refreshAOS = () => typeof AOS !== 'undefined' && AOS?.refreshHard?.();

    router.events.on('routeChangeComplete', refreshAOS);

    return () => {
      router.events.off('routeChangeComplete', refreshAOS);
    };
  }, [router.events]);

  useEffect(() => {
    const handleScroll = () => {
      if (!scrolled) {
        setScrolled(true);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [scrolled]);

  return (
    <>
      <GoogleTagManager gtmId="GTM-TB855HJ" />
      <Head>
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,shrink-to-fit=no"
        />
        {scrolled && (
          <link
            rel="stylesheet"
            href="https://assets.cdn.feather-insurance.com/aos/2.3.1/aos.css"
          />
        )}
      </Head>
      <Script
        src="https://assets.cdn.feather-insurance.com/aos/2.3.1/aos.js"
        strategy="lazyOnload"
        onReady={() => {
          AOS.init();
        }}
      />
      <DefaultSeo
        canonical={canonicalUrl}
        titleTemplate="%s | Feather"
        dangerouslySetAllPagesToNoFollow={!isProduction}
        dangerouslySetAllPagesToNoIndex={!isProduction}
        openGraph={{
          title: 'Feather',
          description:
            'For all your German insurance needs online and in English',
          url: canonicalUrl,
          type: 'website',
          images: [
            {
              url: `${website.base}/static/open-graph-${language ?? 'en'}.jpg`,

              width: 1200,
              height: 630,
              alt: 'Composition with “Honest, simple insurance” written on the right, two persons looking at a laptop on a kitchen counter on the left.',
            },
            {
              url: `${website.base}/static/open-graph-en-2.jpg`,
              width: 1200,
              height: 630,
              alt: 'Composition with "Honest, simple insurance" written on the right hand side, two women sitting on the stairs looking at the Feather app on their phone on the left hand side.',
            },
          ],
        }}
      />
      <OrganizationJsonLd
        type="Organization"
        name="Feather Insurance"
        slogan="Honest, simple insurance."
        legalName="Popsure Deutschland GmbH"
        logo={`${website.base}/static/logo-112x112.png`}
        sameAs={[
          socialMedia.facebook,
          socialMedia.instagram,
          socialMedia.linkedIn,
        ]}
        url={website.base}
        id={`${website.base}#organization`}
      />
      <LiveChatLoaderProvider
        provider="intercom"
        providerKey={process.env.NEXT_PUBLIC_INTERCOM_WORKSPACE_ID ?? ''}
      >
        <NavigationBar
          allRefs={allRefs}
          key={router.asPath}
          menu={mainNav}
          isHiring={isHiring}
          locale={locale}
          subdomain={subdomain}
          shouldShowLanguageSwitcher={shouldShowLanguageSwitcher}
        />
        <Component navOffset={navOffset} {...pageProps} />
        <Footer
          productsMenu={footer}
          locale={locale}
          subdomain={subdomain}
          shouldShowLanguageSwitcher={shouldShowLanguageSwitcher}
        />
        <CookieBanner
          onSave={setTrackersAfterConsent}
          allowScrollOnPaths={['/privacy-policy', '/impressum']}
        />
      </LiveChatLoaderProvider>
    </>
  );
};

/**
 * For pages that use getStaticProps, this function only gets called at build time.
 */
MyApp.getInitialProps = async ({ router }: AppContext) => {
  const locale = router.locale || ENGLISH_LOCALE_IDENTIFIER;

  const mainNavStrapi = await fetchOne(
    router.query.subdomain === BUSINESS_SUBDOMAIN
      ? 'navigation-businesses'
      : 'navigations',
    {
      uid: 'main-navigation',
      locale,
    }
  );

  if (!mainNavStrapi) throw new Error('Main navigation not found');
  const mainNav = getMenuFromStrapiNavigationData(mainNavStrapi);

  const footerStrapi = await fetchOne(
    router.query.subdomain === BUSINESS_SUBDOMAIN
      ? 'navigation-businesses'
      : 'navigations',
    {
      uid: 'footer',
      locale,
    }
  );

  if (!footerStrapi) throw new Error('Footer not found');
  const footer = getFooterFromStrapiNavigationData(footerStrapi);

  const jobs = await fetchMany('jobs', {
    locale: ENGLISH_LOCALE_IDENTIFIER,
  });
  const isHiring = jobs.length > 0;

  const { website_b2b_language_switcher: isBusinessLanguageSwitcherActive } =
    await getFeatureFlags();

  return {
    mainNav,
    footer,
    isHiring,
    isBusinessLanguageSwitcherActive,
  };
};

export default appWithTranslation(MyApp, nextI18NextConfig);
