import { GetValues } from 'strapi/types/utils';

export const getMenuFromStrapiNavigationData = (
  data: GetValues<'api::navigation.navigation'>
) =>
  (data.items ?? []).map((category) => ({
    title: category.categoryTitle,
    icon: category.icon?.data?.attributes.url,
    products: (category.items ?? []).map((item) => ({
      name: item.name,
      url: item.internalPath || item.externalUrl,
    })),
  }));

export const getFooterFromStrapiNavigationData = (
  data: GetValues<'api::navigation.navigation'>
) => {
  const footer = (data.items ?? [])[0]; // footer always has one nav "items"
  return {
    title: footer.categoryTitle,
    products: (footer.items ?? []).map((item) => ({
      id: item.name,
      name: item.name,
      url: item.internalPath || item.externalUrl,
    })),
  };
};
