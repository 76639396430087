import { Card, ChevronRightIcon } from '@popsure/dirty-swan';
import classNames from 'classnames';
import LinkTo from 'components/linkTo';
import Image, { StaticImageData } from 'next/image';
import { useTranslation } from 'next-i18next';
import React from 'react';

import styles from './style.module.scss';

type BannerCardProps = {
  backgroundColor: string;
  title: string;
  description: string;
  href: string;
  image: {
    src: StaticImageData;
    alt: string;
  };
};

export const DesktopBannerCard = ({
  backgroundColor,
  title,
  description,
  image,
  href,
}: BannerCardProps) => {
  const { t } = useTranslation();
  return (
    <Card
      dropShadow={false}
      classNames={{
        wrapper: `jc-between ${backgroundColor}`,
      }}
    >
      <p className="p-h3 mb8">{title}</p>
      <p className="p-p--small mb16">{description}</p>
      <Image
        src={image.src}
        alt={image.alt}
        width={216}
        height={93}
        className="mb16"
      />
      <LinkTo
        href={href}
        className={classNames(
          styles.ctaButton,
          'p-btn--secondary-black w100 d-flex ai-center'
        )}
      >
        {t('component.navigation.product.bannerCard.cta', 'Explore')}
        <ChevronRightIcon size={16} noMargin />
      </LinkTo>
    </Card>
  );
};
