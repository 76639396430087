import { type Subdomain, BUSINESS_SUBDOMAIN } from 'constants/subdomains';

export const getSiteUrl = (subdomain: Subdomain) => {
  if (!process.env.NEXT_PUBLIC_SITE_URL)
    throw Error('SITE URL env variable is not set');
  if (!process.env.NEXT_PUBLIC_SITE_URL_BUSINESS)
    throw Error('SITE URL BUSINESS env variable is not set');

  return subdomain === BUSINESS_SUBDOMAIN
    ? process.env.NEXT_PUBLIC_SITE_URL_BUSINESS
    : process.env.NEXT_PUBLIC_SITE_URL;
};
