import { Card, ChevronRightIcon } from '@popsure/dirty-swan';
import LinkTo from 'components/linkTo';
import React from 'react';

type BannerCardProps = {
  backgroundColor: string;
  title: string;
  description: string;
  href: string;
  cardClassNames?: Record<string, string>;
};

export const MobileBannerCard = ({
  backgroundColor,
  title,
  description,
  href,
  cardClassNames,
}: BannerCardProps) => {
  return (
    <Card
      as={LinkTo}
      href={href}
      dropShadow={false}
      density="compact"
      classNames={{
        wrapper: `${backgroundColor}`,
        ...cardClassNames,
      }}
      actionIcon={false}
    >
      <div className="d-flex ai-center jc-between gap8">
        <div>
          <p className="p-h3 mb8">{title}</p>
          <p className="p-p">{description}</p>
        </div>
        <ChevronRightIcon size={24} color="grey-700" noMargin />
      </div>
    </Card>
  );
};
