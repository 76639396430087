import { ENGLISH_LOCALE_IDENTIFIER } from 'constants/i18n';
import { DEFAULT_SUBDOMAIN } from 'constants/subdomains';

export const isEnDeNonBusinessPage = (
  currentLocale?: string,
  currentSubdomain?: string | string[]
) => {
  const locale = currentLocale ?? ENGLISH_LOCALE_IDENTIFIER;
  const subdomain = currentSubdomain ?? DEFAULT_SUBDOMAIN;
  return (
    locale === ENGLISH_LOCALE_IDENTIFIER && subdomain === DEFAULT_SUBDOMAIN
  );
};
