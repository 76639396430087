import { COOKIE_KEY } from '@getpopsure/cookie-consent';
import LinkTo from 'components/linkTo';
import LanguageSwitcher from 'components/navigationBar/components/LanguageSwitcher';
import { type Locale } from 'constants/i18n';
import { type Subdomain } from 'constants/subdomains';
import { legals } from 'content/navigation/links';
import useDate from 'hooks/useDate';
import Cookies from 'js-cookie';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { isDevelopment } from 'util/env';

import FooterMobileApp from './components/FooterMobileApp';
import FooterNavigation from './components/FooterNavigation';
import styles from './style.module.scss';

interface FooterProps {
  productsMenu: FooterProductsMenu;
  locale: Locale;
  subdomain: Subdomain;
  shouldShowLanguageSwitcher: boolean;
}

const Footer = ({
  productsMenu,
  locale,
  subdomain,
  shouldShowLanguageSwitcher,
}: FooterProps) => {
  const { t } = useTranslation();
  const date = useDate();

  const handleDeleteConsentCookie = () => {
    Cookies.remove(COOKIE_KEY, {
      domain: isDevelopment ? undefined : 'feather-insurance.com',
    });
    window.location.reload();
  };

  const year = date ? date.getFullYear() : '';

  return (
    <div className={`w100 ${styles.container}`}>
      <div className="p-body">
        <FooterNavigation
          productsMenu={productsMenu}
          locale={locale}
          subdomain={subdomain}
        />
        <div className={`d-flex mt24 ${styles.languageSwitcherContainer}`}>
          {shouldShowLanguageSwitcher && (
            <LanguageSwitcher
              condensed={false}
              locale={locale}
              subdomain={subdomain}
            />
          )}
          <FooterMobileApp />
        </div>
        <div className={`mt24 mb16 ${styles.divider}`} />
        <div className={`d-flex ${styles.contentContainer}`}>
          <button
            type="button"
            className={`p-p--small mt8 bg-transparent c-pointer ta-left ${styles.legalLink}`}
            onClick={handleDeleteConsentCookie}
          >
            Cookies
          </button>
          {legals(t).map(({ name, url }) => (
            <LinkTo
              key={url}
              href={url}
              className={`p-p--small mt8 d-inline-block ${styles.legalLink}`}
            >
              {name}
            </LinkTo>
          ))}
        </div>
        <div className="p-p--small tc-grey-500 mt16">
          {t('footer.copyright', {
            year,
          })}
        </div>
      </div>
    </div>
  );
};

export default Footer;
