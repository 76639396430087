import {
  app,
  blog,
  faq,
  legal,
  socialMedia,
  website,
} from '@getpopsure/private-constants';
import {
  AdviceRecommendationIcon,
  BlogIcon,
  BookOpenIcon,
  HeartIcon,
} from '@popsure/dirty-swan';
import {
  ENGLISH_LOCALE_IDENTIFIER,
  GERMAN_LOCALE_IDENTIFIER,
} from 'constants/i18n';
import { nonProductLinks } from 'constants/internalLinks';
import { DEFAULT_SUBDOMAIN } from 'constants/subdomains';
import { StaticImageData } from 'next/image';
import { TFunction } from 'next-i18next';
import facebookIcon from 'public/static/footer/facebook.svg';
import instagramIcon from 'public/static/footer/instagram.svg';
import linkedinIcon from 'public/static/footer/linkedin.svg';

// links in footer
export const resources = (t: TFunction): NavigationLink[] => [
  {
    id: '1',
    name: t('component.navigation.support.footer'),
    url: nonProductLinks.SUPPORT.path,
    locales: nonProductLinks.SUPPORT.supportedLocales,
  },
  {
    id: '2',
    name: t('component.navigation.blog'),
    url: blog.base,
    locales: [ENGLISH_LOCALE_IDENTIFIER, GERMAN_LOCALE_IDENTIFIER],
  },
  {
    id: '3',
    name: t('footer.category.resource.faq'),
    url: faq.base,
    locales: [ENGLISH_LOCALE_IDENTIFIER, GERMAN_LOCALE_IDENTIFIER],
  },
  {
    id: '4',
    name: t('footer.category.resource.recommendation'),
    url: app.recommendationTool,
    locales: [ENGLISH_LOCALE_IDENTIFIER, GERMAN_LOCALE_IDENTIFIER],
  },
];

// links in top navigation
export const resourcesMain = (t: TFunction): NavigationLink[] => [
  {
    name: t('component.navigation.blog'),
    url: blog.base,
    locales: [GERMAN_LOCALE_IDENTIFIER],
    subdomains: [DEFAULT_SUBDOMAIN],
  },
  {
    name: t('component.navigation.support.topNavbar'),
    url: nonProductLinks.SUPPORT.path,
    locales: nonProductLinks.SUPPORT.supportedLocales,
    subdomains: nonProductLinks.SUPPORT.subdomains,
  },
];

// TODO: redundant with nonProductLinks. should use key to get these values in the component instead
// links in top navigation and footer
export const company = (t: TFunction): NavigationLink[] => [
  {
    id: '1',
    name: t('component.navigation.company.about'),
    url: nonProductLinks.ABOUT_US.path,
    locales: nonProductLinks.ABOUT_US.supportedLocales,
    subdomains: nonProductLinks.ABOUT_US.subdomains,
  },
  {
    id: '2',
    name: t('component.navigation.company.careers'),
    url: nonProductLinks.CAREER.path,
    decorator: 'HIRING_BADGE',
    locales: nonProductLinks.CAREER.supportedLocales,
    subdomains: nonProductLinks.CAREER.subdomains,
  },
  {
    id: '3',
    name: t('component.navigation.company.inDepth'),
    url: nonProductLinks.FEATHER_IN_DEPTH.path,
    locales: nonProductLinks.FEATHER_IN_DEPTH.supportedLocales,
    subdomains: nonProductLinks.FEATHER_IN_DEPTH.subdomains,
  },
  {
    id: '4',
    name: t('component.navigation.company.press'),
    url: nonProductLinks.PRESS.path,
    decorator: 'SEPARATOR',
    locales: nonProductLinks.PRESS.supportedLocales,
    subdomains: nonProductLinks.PRESS.subdomains,
  },
  {
    id: '5',
    name: t('component.navigation.company.partnership'),
    url: nonProductLinks.BECOME_A_PARTNER.path,
    locales: nonProductLinks.BECOME_A_PARTNER.supportedLocales,
    subdomains: nonProductLinks.BECOME_A_PARTNER.subdomains,
  },
];

export const advice = (t: TFunction): NavigationLink[] => [
  {
    id: '1',
    name: t('component.navigation.advice.needs'),
    url: app.recommendationTool,
    icon: <AdviceRecommendationIcon size={20} noMargin />,
  },
  {
    id: '2',
    name: t('component.navigation.advice.compare'),
    url: `${website.base}/health-insurance`,
    icon: <HeartIcon size={20} noMargin />,
  },
  {
    id: '3',
    name: t('component.navigation.advice.faq'),
    url: faq.base,
    icon: <BookOpenIcon size={20} noMargin />,
  },
  {
    id: '4',
    name: t('component.navigation.advice.blog'),
    url: blog.base,
    icon: <BlogIcon size={20} noMargin />,
  },
];

export const socialMediaLinks: {
  id: string;
  name: string;
  image: StaticImageData;
  url: string;
}[] = [
  {
    id: '1',
    name: 'Facebook',
    image: instagramIcon,
    url: socialMedia.instagram,
  },
  {
    id: '2',
    name: 'Instagram',
    image: facebookIcon,
    url: socialMedia.facebook,
  },
  {
    id: '3',
    name: 'LinkedIn',
    image: linkedinIcon,
    url: socialMedia.linkedIn,
  },
];

export const legals = (t: TFunction): NavigationLink[] => [
  { name: t('footer.category.legal.imprint'), url: website.impressum },
  {
    name: t('footer.category.legal.privacy'),
    url: website.privacyPolicy,
  },
  {
    name: t('footer.category.legal.terms'),
    url: website.termsAndConditions,
  },
  {
    name: t('footer.category.legal.security'),
    // TODO: add to private constants
    url: `${website.base}/security/vulnerability-disclosure-policy`,
  },
  {
    name: t('footer.category.legal.introductoryinfo'),
    url: legal.introductoryInformation,
  },
];
