import { blog } from '@getpopsure/private-constants';
import { captureException } from '@sentry/nextjs';
import { QuotePayload } from 'models/life-insurance';

export const submitLifeQuoteRequest = (payload: QuotePayload) =>
  fetch(`${process.env.NEXT_PUBLIC_FEATHER_API_URL}/life/quote`, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

export const submitApplicationForm = async (formData: FormData) => {
  const response = await fetch('/api/applications', {
    method: 'POST',
    body: formData,
  });
  return response.json();
};

async function fetchAPI(
  query: string,
  { variables }: { variables?: Record<string, number | string> } = {}
) {
  const headers = { 'Content-Type': 'application/json' };

  const res = await fetch(`${blog.base}/graphql`, {
    method: 'POST',
    headers,
    body: JSON.stringify({ query, variables }),
  });

  const json = await res.json();
  if (json.errors) {
    captureException(json.errors);
    throw new Error('Failed to fetch API');
  }

  return json.data;
}

export async function getLatestPostsByCategory(categoryId = 40) {
  const data = await fetchAPI(
    `
        query AllPostsByCategoryId($categoryId: Int) {
          posts(first: 3, where: { categoryId: $categoryId, orderby: { field: DATE, order: DESC } }) {
            edges {
              node {
                id
                title
                excerpt
                link
                featuredImage {
                  node {
                    sourceUrl
                    altText
                  }
                }
              }
            }
          }
        }
      `,
    {
      variables: {
        categoryId,
      },
    }
  );

  return data?.posts.edges;
}

export async function getFeatureFlags() {
  let featureFlags: Record<string, boolean> = {};
  try {
    const response = await fetch(process.env.UNLEASH_API_URL || '', {
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: process.env.UNLEASH_CLIENT_KEY || '',
      },
    });

    const data: {
      toggles: Array<{
        name: string;
        enabled: boolean;
        variant: { name: string; enabled: boolean };
      }>;
    } = await response.json();

    featureFlags = data?.toggles.reduce(
      (flags, { name, enabled }) => ({
        ...flags,
        [name]: enabled,
      }),
      {}
    );
  } catch (error) {
    captureException(error);
  }

  return featureFlags;
}
