export const ENGLISH_LOCALE_IDENTIFIER: Locale = 'en-de';
export const GERMAN_LOCALE_IDENTIFIER: Locale = 'de-de';
export const FORMATTED_DEFAULT_LOCALE = 'en-DE';

// Locales supported to signup on app
export const FULLY_SUPPORTED_LOCALES = [
  'en-de',
  'de-de',
  'en-es',
  'es-es',
  'en-fr',
  'fr-fr',
];

export const STRAPI_LOCALES = Array.from(
  new Set([
    ...FULLY_SUPPORTED_LOCALES,
    'en-be',
    'en-gr',
    'en-it',
    'en-mt',
    'en-nl',
    'en-se',
    'en-pt',
    'en-eu',
  ])
);

export const BUSINESS_SUBDOMAIN_LOCALES = [
  ENGLISH_LOCALE_IDENTIFIER,
  GERMAN_LOCALE_IDENTIFIER,
];

// should be same list as locales.js
export const LOCALES = STRAPI_LOCALES;

export type Locale = typeof LOCALES[number];
