import {
  type Subdomain,
  DEFAULT_SUBDOMAIN,
  SUBDOMAINS,
} from 'constants/subdomains';

export const isValidSubdomain = (subdomain: string): subdomain is Subdomain =>
  (SUBDOMAINS as readonly string[]).includes(subdomain);

export const validateSubdomain = (subdomain: unknown): Subdomain => {
  if (!subdomain) return DEFAULT_SUBDOMAIN; // for cases when router.query.subdomain is undefined, as router.query will be an empty object during prerendering

  if (typeof subdomain !== 'string') throw Error('subdomain is not a string');
  if (!isValidSubdomain(subdomain)) throw Error('subdomain is not valid');
  return subdomain;
};
