import { captureException } from '@sentry/nextjs';
import { STRAPI_LOCALES } from 'constants/i18n';
import { STRAPI_UID_SEPARATOR } from 'constants/strapi';
import { DEFAULT_SUBDOMAIN } from 'constants/subdomains';
import {
  APIResponseCollectionMetadata,
  APIResponseData,
  GetValues,
  StrapiHomepageData,
  StrapiPageData,
} from 'strapi/types/utils';
import Strapi, { StrapiLocale } from 'strapi-sdk-js';

type ResponseTypeMapping = {
  pages: 'api::page.page';
  'page-businesses': 'api::page-business.page-business';
  navigations: 'api::navigation.navigation';
  'navigation-businesses': 'api::navigation-business.navigation-business';
  homepages: 'api::homepage.homepage';
  'homepage-businesses': 'api::homepage-business.homepage-business';
  tables: 'api::table.table';
  jobs: 'api::job.job';
  'company-blogs': 'api::company-blog.company-blog';
  'announcement-bars': 'api::announcement-bar.announcement-bar';
};

export type CollectionType = keyof ResponseTypeMapping;

export type GetResponseType<K extends CollectionType> = ResponseTypeMapping[K];

const createStrapiClient = () => {
  return new Strapi({
    url: process.env.STRAPI_API_URL,
    axiosOptions: {
      headers: { Authorization: `Bearer ${process.env.STRAPI_TOKEN}` },
    },
  });
};

export const fetchMany = async <T extends CollectionType>(
  collection: T,
  {
    locale,
    strapiClient,
  }: {
    locale: string;
    strapiClient?: Strapi;
  }
): Promise<GetValues<GetResponseType<T>>[]> => {
  const strapi = strapiClient ?? createStrapiClient();

  const results: GetValues<GetResponseType<T>>[] = [];
  let currentPage = 1;

  while (true) {
    // eslint-disable-next-line no-await-in-loop
    const { data, meta } = await strapi.find<
      APIResponseData<GetResponseType<T>>[]
    >(collection, {
      locale: locale as StrapiLocale,
      populate: 'deep',
      pagination: { page: currentPage, pageSize: 25 },
    });

    const {
      pagination: { pageCount },
    } = meta as unknown as APIResponseCollectionMetadata;

    results.push(...data.map((page) => page.attributes));

    if (currentPage >= pageCount) break;

    currentPage++;
  }

  return results;
};

export const fetchOne = async <T extends CollectionType>(
  collection: T,
  {
    locale,
    uid,
    strapiClient,
    preview,
  }: {
    locale: string;
    uid: string;
    strapiClient?: Strapi;
    preview?: boolean;
  }
): Promise<GetValues<GetResponseType<T>> | null> => {
  const strapi = strapiClient ?? createStrapiClient();

  const { data } = await strapi.find<APIResponseData<GetResponseType<T>>[]>(
    collection,
    {
      locale: locale as StrapiLocale,
      populate: 'deep',
      filters: {
        uid: {
          $eq: `${locale}${STRAPI_UID_SEPARATOR}${uid}`,
        },
      },
      publicationState: preview ? 'preview' : 'live',
    }
  );

  if (data.length > 1) {
    throw Error('Too many results');
  } else if (data.length === 0) {
    return null;
  }

  return data[0].attributes;
};

export const getPathSegmentsFromPageDoc = (
  page: StrapiPageData | StrapiHomepageData
) => {
  if (!page.uid) return [];

  const segments = page.uid.split(STRAPI_UID_SEPARATOR).slice(1);

  return segments;
};

const isNestedPath = (uid: string) => uid.includes(STRAPI_UID_SEPARATOR);

export const getUidWithoutLocale = (uid: string): string => {
  const [localeSegment, ...otherSegments] = uid.split(STRAPI_UID_SEPARATOR);

  if (!STRAPI_LOCALES.includes(localeSegment)) {
    throw Error("The provided UID didn't contain a valid locale segment");
  }

  if (otherSegments.length === 0) {
    throw Error("The provided UID didn't contain any non-locale segments");
  }

  return otherSegments.join(STRAPI_UID_SEPARATOR);
};

export const isValidUid = (uid: string): boolean => {
  const regex = /^[a-zA-Z0-9-]+$/;
  return regex.test(uid);
};

export const getPathFromUidWithoutLocale = (uid: string) => {
  const pathName = isNestedPath(uid)
    ? uid.replaceAll(STRAPI_UID_SEPARATOR, '/')
    : uid;

  return pathName;
};

export const fetchAnnouncementBar = async ({
  uid,
  locale,
  preview,
  subdomain,
}: {
  uid: string;
  locale: string;
  subdomain: string;
  preview?: boolean;
}): Promise<GetValues<GetResponseType<'announcement-bars'>> | null> => {
  if (subdomain !== DEFAULT_SUBDOMAIN) return null;

  try {
    return await fetchOne('announcement-bars', {
      uid,
      locale,
      preview,
    });
  } catch (e) {
    const errorMessage = 'Failed to fetch announcement bar';
    captureException(e, {
      extra: {
        uid,
        locale,
        description: errorMessage,
      },
    });
    throw new Error(errorMessage);
  }
};
