import { type Locale } from 'constants/i18n';
import { type Subdomain } from 'constants/subdomains';

export const getMenuByLocaleAndSubdomain = (
  links: NavigationLink[],
  locale: Locale,
  subdomain: Subdomain
) =>
  links
    .filter(({ locales }) => !(locale && locales && !locales.includes(locale)))
    .filter(
      ({ subdomains }) => !(subdomains && !subdomains.includes(subdomain))
    );
