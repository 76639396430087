import classNames from 'classnames';
import LinkTo from 'components/linkTo';
import { ENGLISH_LOCALE_IDENTIFIER } from 'constants/i18n';
import {
  BUSINESS_SUBDOMAIN,
  DEFAULT_SUBDOMAIN,
  Subdomain,
} from 'constants/subdomains';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import businessImage from 'public/static/components/NavigationBar/b2b.png';
import defaultImage from 'public/static/components/NavigationBar/b2c.png';
import React from 'react';
import { getSiteUrl } from 'util/getSiteUrl';

import BannerCard from '../DesktopBannerCard';
import styles from './style.module.scss';

const Category = ({
  title,
  icon,
  children,
}: {
  title: string;
  icon: string;
  children: React.ReactNode;
}) => (
  <li className={styles.category}>
    <div className={`${styles.categoryHeader} d-flex ai-center`}>
      <Image src={icon} width={40} height={40} alt={title} />
      <div className={`tc-grey-600 fw-bold ${styles.categoryTitle}`}>
        {title}
      </div>
    </div>
    <div className={styles.divider} />
    {children}
  </li>
);

export const DropdownMenuWithCategory = ({
  menu,
  locale,
  subdomain,
}: {
  menu: NavigationProductCategory[];
  locale: Locale;
  subdomain: Subdomain;
}) => {
  const { t } = useTranslation();
  const showBannerCard = locale === ENGLISH_LOCALE_IDENTIFIER;

  return (
    <div
      className={`br8 px16 ${styles.dropdown} ${styles.menuWithCategoriesContainer}`}
    >
      <ul
        className={classNames('d-flex gap56 w100 pt32 pb48 mx-auto', {
          'jc-around': showBannerCard,
          'jc-center': !showBannerCard,
        })}
      >
        {menu.map((category) => (
          <Category
            key={category.title}
            title={category.title}
            icon={category.icon}
          >
            <ul>
              {category.products.map(({ name, url }) => (
                <li className="mt24" key={name}>
                  <LinkTo
                    href={url}
                    className={classNames(styles.productContainer, 'br4')}
                    aria-label={name}
                  >
                    {name}
                  </LinkTo>
                </li>
              ))}
            </ul>
          </Category>
        ))}
        {showBannerCard && (
          <li className={styles.bannerCard}>
            {subdomain === DEFAULT_SUBDOMAIN ? (
              <BannerCard
                backgroundColor="bg-primary-100"
                title={t(
                  'component.navigation.product.bannerCard.b2b.title',
                  'Company insurance'
                )}
                description={t(
                  'component.navigation.product.bannerCard.b2b.description',
                  'Flexible plans made for your business.'
                )}
                image={{
                  src: businessImage,
                  alt: 'Health and pension insurances on a phone screen',
                }}
                href={getSiteUrl(BUSINESS_SUBDOMAIN)}
              />
            ) : (
              <BannerCard
                backgroundColor="bg-blue-100"
                title={t(
                  'component.navigation.product.bannerCard.b2c.title',
                  'Personal coverage'
                )}
                description={t(
                  'component.navigation.product.bannerCard.b2c.description',
                  'Flexible plans made for individuals.'
                )}
                image={{
                  src: defaultImage,
                  alt: 'Health and household insurances on a phone screen',
                }}
                href={getSiteUrl(DEFAULT_SUBDOMAIN)}
              />
            )}
          </li>
        )}
      </ul>
    </div>
  );
};
